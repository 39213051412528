import { FunctionalComponent, h } from "preact"
import { Route, Router } from "preact-router"
import { useState } from "preact/hooks"
import About from "../routes/about"

import Cabins from "../routes/cabins"
import FAQ from "../routes/faq"
import NotFoundPage from "../routes/notfound"
import Staff from "../routes/staff"
import Header from "./header"
import MobileNav from "./mobile-nav"

const App: FunctionalComponent = () => {
  const [navOpen, setNavOpen] = useState(false)
  const [theme, setTheme] = useState("default")
  return (
    <div id="preact_root" className={`theme--${theme}`}>
      <Header
        onOpenNav={(): void => setNavOpen(true)}
        setTheme={(theme: string): void => setTheme(theme)}
        theme={theme}
      />
      <MobileNav open={navOpen} onClose={(): void => setNavOpen(false)} />
      <a id="top" />
      <Router>
        <Route path="/" component={Cabins} />
        <Route path="/about" component={About} />
        <Route path="/faq" component={FAQ} />
        <Route path="/staff" component={Staff} />
        <NotFoundPage default />
      </Router>
    </div>
  )
}

export default App
