import { FunctionalComponent, Fragment, h } from "preact"
import { Suspense, lazy } from "preact/compat"
import style from "./style.scss"

const icons = [
  "arrow_down",
  "arrow_left",
  "arrow_right",
  "arrow_up",
  "attachment",
  "bookmark",
  "calendar",
  "camera",
  "chat",
  "close",
  "cog",
  "copy",
  "edit",
  "envelope",
  "folder",
  "home",
  "link",
  "menu",
  "minus",
  "plus",
  "save",
  "search",
  "trash",
  "star",
  "contrast",
] as const

export type IconType = typeof icons[number]

type IconProps = {
  type: IconType
  alt?: string
  className?: string
  onClick?: () => void
}

const svgDir = require.context("!@svgr/webpack!../../assets/images/icons/")

const Icon: FunctionalComponent<IconProps> = ({
  type,
  alt,
  className,
  onClick,
}) => {
  const Vector = svgDir(`./ic_${type}.svg`).default
  return (
    <div
      class={`${style.image} ${className ? ` ${className}` : ""} ${
        onClick ? style.clickable : ""
      }`}
      alt={alt ?? type}
      onClick={onClick}
    >
      <Vector />
    </div>
  )
}

export default Icon
