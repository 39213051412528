import usePrevious from "@react-hook/previous"
import useScrollPosition from "@react-hook/window-scroll"
import { FunctionalComponent, Fragment, h } from "preact"
import { useMediaQuery } from "react-responsive"
import { useState } from "preact/hooks"
import Icon from "../icon"
import style from "./style.scss"
import Chip from "../chip"

type HeaderProps = {
  onOpenNav: () => void
  setTheme: (theme: string) => void
  theme: string
}

const Header: FunctionalComponent<HeaderProps> = ({
  onOpenNav,
  theme,
  setTheme,
}) => {
  const scrollY = useScrollPosition(15)
  const prevY = usePrevious(scrollY)
  const [hidden, setHidden] = useState(true)
  const [animationFinished, setAnimationFinished] = useState(false)
  const [animationStarted, setAnimationStarted] = useState(false)
  const [passedMountain, setPassedMountain] = useState(false)
  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  })
  // scroll same or up
  const scrollUp: boolean = scrollY > 0 && scrollY < (prevY ?? 0)
  const scrollDown: boolean = scrollY > 0 && scrollY > (prevY ?? 0)
  const noScroll: boolean = scrollY == (prevY ?? 0)

  if (scrollDown && scrollY > 100) {
    setPassedMountain(true)
  }

  if (scrollY == 0) {
    setPassedMountain(false)
    setHidden(true)
  }

  if (scrollDown && !hidden) {
    setAnimationStarted(true)
  }

  if ((scrollDown || noScroll) && animationFinished) {
    setHidden(true)
  }

  if (scrollUp) {
    setAnimationStarted(false)
    setAnimationFinished(false)
    setHidden(false)
  }

  return (
    <>
      <header
        class={`${style.header} ${
          !hidden && passedMountain ? style.floating : ""
        } ${
          !hidden && passedMountain && animationStarted ? style.animateUp : ""
        }`}
        onAnimationEnd={(): void => {
          // if we're playing the hiding animation rather than the opening one
          if (animationStarted) {
            setAnimationFinished(true)
          }
        }}
      >
        <div class={style.menu}>
          {isDesktop ? (
            <>
              <img
                src="assets/images/standard-logo.svg"
                class={style.wordmark}
                alt="HMHF Logo"
              />
              <ul class={style.hoverLinks}>
                <li>
                  <a href="/about">About</a>
                  <ul class={style.dropdown}>
                    <li>
                      <a href="/staff">Staff & Volunteering</a>
                    </li>
                    <li>
                      <a href="/faq">FAQ</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">Venue</a>
                  <ul class={style.dropdown}>
                    <li>
                      <a href="/">Cabins & Campsite</a>
                    </li>
                    <li>
                      <a href="#">Travelling to HMHF</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">Contact</a>
                </li>
                <li>
                  <Chip
                    onClick={(): void => {
                      if (theme == "contrast") {
                        setTheme("default")
                      } else {
                        setTheme("contrast")
                      }
                    }}
                    text={theme == "contrast" ? "ON" : "OFF"}
                    darkBg={theme != "contrast"}
                    icon="contrast"
                  />
                </li>
              </ul>
            </>
          ) : (
            <>
              <picture>
                <source
                  type="image/webp"
                  srcset="assets/images/wordmark-starfield@3x.webp"
                />
                <source
                  type="image/png"
                  srcset="assets/images/wordmark-starfield@3x.png"
                />
                <img
                  src="assets/images/wordmark-starfield@3x.png"
                  class={style.wordmark}
                  alt="HMHF wordmark"
                />
              </picture>
              <Chip
                onClick={(): void => {
                  if (theme == "contrast") {
                    setTheme("default")
                  } else {
                    setTheme("contrast")
                  }
                }}
                text={theme == "contrast" ? "ON" : "OFF"}
                darkBg={theme != "contrast"}
                icon="contrast"
                className={style.contrastChip}
              />
              <Icon
                type="menu"
                className={style.hamburger}
                alt="toggle menu"
                onClick={onOpenNav}
              />
            </>
          )}
        </div>
      </header>
      <div class={style.blobContainer}>
        <div class={style.blob}>
          <img
            src="assets/images/header-blob.svg"
            alt="abstract mountain shapes"
          />
        </div>
      </div>
    </>
  )
}

export default Header
