import { FunctionalComponent, h } from "preact"
import style from "./style.scss"
import Icon, { IconType } from "../icon"

type ChipProps = {
  text: string
  icon: IconType
  darkBg: boolean
  onClick?: () => void
  className?: string
}

const Chip: FunctionalComponent<ChipProps> = ({
  text,
  icon,
  darkBg,
  onClick,
  className,
}) => {
  return (
    <div
      class={`${style.container} ${darkBg ? style.starfield : style.lunar} ${
        className ?? ""
      }`}
      onClick={onClick}
    >
      <Icon className={style.icon} type={icon} />
      <span class={style.chipText}>{text}</span>
    </div>
  )
}

export default Chip
