import { FunctionalComponent, Fragment, h } from "preact"
import { useState } from "preact/hooks"
import Icon from "../icon"
import style from "./style.scss"

type NavProps = {
  open: boolean
  onClose: () => void
}

const MobileNav: FunctionalComponent<NavProps> = ({ open, onClose }) => {
  const [closeAnimationFinished, setCloseAnimationFinished] = useState(false)
  const [shouldClose, setShouldClose] = useState(false)

  if (shouldClose && closeAnimationFinished) {
    onClose()
    setShouldClose(false)
    setCloseAnimationFinished(false)
  }
  return open ? (
    <nav
      class={`${style.nav} ${shouldClose ? style.closing : ""}`}
      onAnimationEnd={(): void => {
        if (shouldClose) {
          setCloseAnimationFinished(true)
        }
      }}
    >
      <Icon
        type="close"
        className={style.closeIcon}
        onClick={(): void => setShouldClose(true)}
      />
      <div class={style.links}>
        <a href="/" onClick={(): void => setShouldClose(true)}>
          Home
        </a>

        <a href="/about" onClick={(): void => setShouldClose(true)}>
          About
        </a>
        <a
          href="/staff"
          onClick={(): void => setShouldClose(true)}
          class={style.smallLink}
        >
          Staff & Volunteering
        </a>
        <a
          href="/faq"
          onClick={(): void => setShouldClose(true)}
          class={style.smallLink}
        >
          FAQ
        </a>

        <a href="/" onClick={(): void => setShouldClose(true)}>
          Venue
        </a>
        <a
          href="/"
          onClick={(): void => setShouldClose(true)}
          class={style.smallLink}
        >
          Cabin & Campsite
        </a>
        <a
          href="/"
          onClick={(): void => setShouldClose(true)}
          class={style.smallLink}
        >
          Travelling to HMHF
        </a>

        <a href="/" onClick={(): void => setShouldClose(true)}>
          Contact
        </a>
      </div>
      <img
        src="assets/images/dancing-wolves.svg"
        alt="dancing wolves"
        class={style.dancingWolves}
      />
    </nav>
  ) : (
    <></>
  )
}

export default MobileNav
